import { arrayToMultiMap, getWithDefault } from '@sixfold/typed-primitives';
import React from 'react';
import {
  List,
  Popup,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Icon,
} from 'semantic-ui-react';

import { FormattedDate } from '../../../components/date_formatting/formatted_date';
import { CompanyOnboardingQuery } from '../../../lib/graphql';
import { beautifyString } from '../../../lib/util/string';

export const SignupVisibilitySources: React.FC<{
  visibilitySources: NonNullable<NonNullable<CompanyOnboardingQuery['company']>['signupVisibilitySources']>;
  events: NonNullable<CompanyOnboardingQuery['company']>['unifiedSignupEvents'];
}> = ({ visibilitySources, events }) => {
  if (visibilitySources.length === 0) {
    return <>-</>;
  }

  const eventsBySource = arrayToMultiMap(events ?? [], ({ visibilitySource }) => visibilitySource ?? '-');

  return (
    <List horizontal>
      {visibilitySources.map((source, idx) => {
        const events = getWithDefault(eventsBySource, source.type, []);

        return (
          <List.Item key={idx}>
            <Popup
              wide="very"
              content={
                events.length === 0 ? (
                  source.status
                ) : (
                  <Table compact basic="very">
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell width="6">Time</TableHeaderCell>
                        <TableHeaderCell width="10">Event</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {events.map(({ eventTime, type }, idx) => (
                        <TableRow key={idx}>
                          <TableCell>
                            <FormattedDate date={eventTime} />
                          </TableCell>
                          <TableCell>
                            <span>{beautifyString(type)}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )
              }
              trigger={
                <span>
                  <Icon
                    name={source.status === 'ACTIVE' ? 'check' : 'question'}
                    color={source.status === 'ACTIVE' ? 'green' : 'yellow'}
                  />
                  {source.type}
                </span>
              }
            />
          </List.Item>
        );
      })}
    </List>
  );
};
