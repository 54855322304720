import { isNil, notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  GridRow,
  GridColumn,
  Dropdown,
  Button,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from 'semantic-ui-react';

import { OnboardingViewProps } from './company_onboarding';
import { VisibilitySources } from './visibility_sources';
import { FormattedDate } from '../../../components/date_formatting/formatted_date';
import {
  UnifiedCompanyOnboardingStatusUpdateType,
  UnifiedCompanyOnboardingStuckReason,
  UnifiedCompanyOnboardingEventType,
} from '../../../lib/graphql';
import { beautifyString } from '../../../lib/util/string';
import { Routes } from '../../../routes';

export const OnboardingStatus: React.FC<OnboardingViewProps> = (props) => {
  const onboarding = {
    status: props.data.company?.unifiedOnboardingStatus ?? null,
    stuckReason: props.data.company?.unifiedOnboardingStuckReason ?? null,
    events: props.data.company?.unifiedOnboardingEvents ?? [],
    visibilitySources: props.data.company?.visibilitySources ?? [],
    visibilitySourcesViaSubcarriers: props.data.company?.visibilitySourcesViaSubcarriers ?? [],
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const updateOnboardingStatus = React.useCallback(
    async (update: UnifiedCompanyOnboardingStatusUpdateType, stuckReason?: UnifiedCompanyOnboardingStuckReason) => {
      const companyId = props.data.company?.company_id;

      if (isNil(companyId)) {
        throw new Error('No company to update');
      }

      setIsLoading(true);

      try {
        await props.updateOnboardingStatus(companyId, { update, stuckReason });
      } finally {
        setIsLoading(false);
      }
    },
    [props],
  );

  return (
    <Grid>
      <GridRow>
        <GridColumn width="12" verticalAlign="middle">
          Onboarding status: <b>{notNil(onboarding.status) ? beautifyString(onboarding.status) : 'N/A'}</b>
          {notNil(onboarding.stuckReason) && (
            <>
              {' '}
              (reason: <b>{beautifyString(onboarding.stuckReason)}</b>)
            </>
          )}
        </GridColumn>
        <GridColumn width="4" textAlign="right">
          <Dropdown
            disabled={isLoading}
            icon={null}
            pointing="top right"
            trigger={
              <Button size="mini" loading={isLoading}>
                Update onboarding status
              </Button>
            }>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => updateOnboardingStatus(UnifiedCompanyOnboardingStatusUpdateType.RESET_TO_AUTOMATIC)}>
                Reset to calculated status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateOnboardingStatus(UnifiedCompanyOnboardingStatusUpdateType.ONBOARDED)}>
                Manually onboarded
              </Dropdown.Item>
              <Dropdown.Header>Onboarding stuck</Dropdown.Header>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.COMPANY_UNRESPONSIVE,
                  )
                }>
                Company unresponsive
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.FMS_CREDENTIALS_NOT_PROVIDED,
                  )
                }>
                FMS credentials not provided
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.FMS_PROVIDER_UNRESPONSIVE,
                  )
                }>
                FMS provider unresponsive
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.NO_VISIBILITY_SOURCE_IDENTIFIED,
                  )
                }>
                No visibility source identified
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.SUBCARRIER_ONBOARDING_BLOCKED,
                  )
                }>
                Subcarrier onboarding blocked
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.REFUSES_COOPERATION,
                  )
                }>
                Carrier refuses cooperation
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  updateOnboardingStatus(
                    UnifiedCompanyOnboardingStatusUpdateType.ONBOARDING_STUCK,
                    UnifiedCompanyOnboardingStuckReason.CARRIER_API_WORK_IN_PROGRESS,
                  )
                }>
                Carrier is working on API
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn>
          Visibility sources:{' '}
          <VisibilitySources visibilitySources={onboarding.visibilitySources} events={onboarding.events} />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          Visibility sources via subcarriers:{' '}
          <VisibilitySources visibilitySources={onboarding.visibilitySourcesViaSubcarriers} events={null} />
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn width="16">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell width="4">Time</TableHeaderCell>
                <TableHeaderCell width="8">Event</TableHeaderCell>
                <TableHeaderCell width="4">Triggered by</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {onboarding.events.length === 0 ? (
                <TableRow>
                  <TableCell textAlign="center" colSpan="3">
                    No events
                  </TableCell>
                </TableRow>
              ) : (
                onboarding.events.map(({ eventTime, type, triggeredByUser, visibilitySource, stuckReason }, idx) => (
                  <TableRow
                    key={idx}
                    warning={type === UnifiedCompanyOnboardingEventType.MANUALLY_ONBOARDED}
                    positive={type === UnifiedCompanyOnboardingEventType.ONBOARDED}>
                    <TableCell>
                      <FormattedDate date={eventTime} />
                    </TableCell>
                    <TableCell>
                      <span>{beautifyString(type)}</span>
                      {visibilitySource !== null || stuckReason !== null ? (
                        <span> ({[visibilitySource, stuckReason].filter(notNil).join(', ')})</span>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {triggeredByUser?.user_id !== undefined ? (
                        <Link to={Routes.User.generatePath({ user_id: triggeredByUser.user_id })}>
                          {triggeredByUser.first_name} {triggeredByUser.last_name}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
