import { Stack, Toggle, Tooltip, Button } from '@sixfold/common-ui';
import React from 'react';
import { Grid, GridColumn } from 'semantic-ui-react';

import { OnboardingStatus } from './onboarding_status';
import { SignupStatus } from './signup_status';
import { CompanyOnboardingQuery, UnifiedCompanyOnboardingStatusUpdateInput } from '../../../lib/graphql';
import { CompanyInvitationsContainer } from '../../containers/company_invitations_container';

export interface OnboardingViewProps {
  data: CompanyOnboardingQuery;
  updateOnboardingStatus: (companyId: string, input: UnifiedCompanyOnboardingStatusUpdateInput) => Promise<void>;
}

export const CompanyOnboardingView: React.FC<OnboardingViewProps> = (props) => {
  const [showSignupStatus, setShowSignupStatus] = React.useState(false);
  return (
    <Grid>
      <GridColumn width="7">
        <Stack className="m8__horizontal" alignItems="center" justifyContent="space-between">
          <div>
            <h2>Onboarding status</h2>
          </div>
          <Toggle label="Signup status" onChange={() => setShowSignupStatus(!showSignupStatus)} />
        </Stack>
        <OnboardingStatus {...props} />
      </GridColumn>
      <GridColumn width="7">
        {showSignupStatus ? (
          <>
            <Stack className="m8__horizontal" alignItems="center">
              <div>
                <h2>Signup status</h2>
              </div>
              <Tooltip content="Signup status is a replacement for onboarding status that is currently in development">
                <Button size="xsmall" kind="ghost" iconStart="small-help" hideLabel>
                  Help
                </Button>
              </Tooltip>
            </Stack>
            <SignupStatus {...props} />
          </>
        ) : props.data.company ? (
          <>
            <h2>Received invitations</h2>
            <CompanyInvitationsContainer companyId={props.data.company?.company_id} />
          </>
        ) : null}
      </GridColumn>
    </Grid>
  );
};
