import { useNotifications } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { CompanyTagsMutations } from './company_tag_container';
import {
  CompanyDetailsQuery,
  CompanyDetailsQueryVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '../../lib/graphql';
import { CompanyView, CompanyViewDataProps } from '../components/company';
import { CompanyForm } from '../components/company_form';
import { companyQuery, createCompanyMutation, updateCompanyMutation } from '../graphql';

class LoadingContainer extends Loader<CompanyDetailsQuery, CompanyViewDataProps, CompanyDetailsQueryVariables> {}
class CompanyContainerQuery extends Query<CompanyDetailsQuery, CompanyDetailsQueryVariables> {}
class CompanyContainerCreateCompanyMutation extends Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> {}
class CompanyContainerUpdateCompanyMutation extends Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {}

export const CompanyContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const isNewCompany = props.match.params.company_id === 'new';
  const notify = useNotifications();

  if (isNewCompany) {
    return (
      <CompanyContainerCreateCompanyMutation mutation={createCompanyMutation}>
        {(createCompany) => (
          <CompanyForm
            data={{ company: null }}
            createCompany={async (input) => {
              const response = await createCompany(input);
              notify.success({ title: 'Company created' });
              return response;
            }}
            routeProps={{ goBack: props.history.goBack }}
          />
        )}
      </CompanyContainerCreateCompanyMutation>
    );
  }

  return (
    <CompanyContainerQuery
      query={companyQuery}
      variables={{
        company_id: props.match.params.company_id,
      }}>
      {(result) => {
        return (
          <CompanyTagsMutations>
            {({ addCompanyTag, removeCompanyTag }) => (
              <CompanyContainerUpdateCompanyMutation mutation={updateCompanyMutation}>
                {(updateCompany) => (
                  <LoadingContainer
                    result={result}
                    mapData={({ data }) => ({
                      data: {
                        company: data.company ?? null,
                        availableTags: data.availableCompanyTags ?? [],
                        identifiers:
                          data.company?.companyIdentifiers.map((identifier) => ({
                            ...identifier,
                            country: identifier.discriminatorCountry ?? undefined,
                          })) ?? [],
                      },
                    })}>
                    {({ data }) => (
                      <CompanyView
                        data={data}
                        updateCompany={async (input) => {
                          const response = await updateCompany(input);
                          notify.success({ title: 'Company updated' });
                          return response;
                        }}
                        onAddCompanyTag={addCompanyTag}
                        onRemoveCompanyTag={removeCompanyTag}
                      />
                    )}
                  </LoadingContainer>
                )}
              </CompanyContainerUpdateCompanyMutation>
            )}
          </CompanyTagsMutations>
        );
      }}
    </CompanyContainerQuery>
  );
};
