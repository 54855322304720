import { gql } from 'graphql-tag';

export const companyRelationshipsListQuery = gql`
  query CompanyRelationshipsList($limit: Int, $cursor: String, $companyId: String!) {
    companyRelationships(first: $limit, after: $cursor, companyId: $companyId) {
      edges {
        node {
          companyRelationshipId
          relationIdentifiers {
            companyIdentifierId
            identifierValue
            source
          }
          sourceCompany {
            company_name
            company_id
          }
          targetCompany {
            company_name
            company_id
          }
          relationshipType
          unifiedOnboardingStatus
          unifiedOnboardingStuckReason
          unifiedOnboardingEvents {
            type
            eventTime
            triggeredByUser {
              first_name
              last_name
              user_id
            }
          }
          unifiedSignupStatus
          unifiedSignupEvents {
            type
            eventTime
            triggeredByUser {
              first_name
              last_name
              user_id
            }
          }
          dataSharingConsent {
            tourStatusScope
            history {
              consentSource
              tourStatusScope
              updatedByUser {
                first_name
                last_name
                user_id
              }
              updatedAt
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
