import { notNil, safeParseDate } from '@sixfold/typed-primitives';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Popup, Table } from 'semantic-ui-react';

import { TimestampLink } from './timestamp_link';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { compareAsc } from '../../lib/date';
import { Routes } from '../../routes';
import { isTourExternalEvent, isTourExternalStopEvent, isTourExternalDeliveryEvent, ExternalEvent } from '../entities';
import { breakWordAtChar } from '../utils/break_word_util';

export interface ExternalEventListProps {
  events: ExternalEvent[];
}

function parseDateWithDefault(dateStr: string | null, defaultDate: Date): Date {
  const date = safeParseDate(dateStr);
  return date !== null ? date : defaultDate;
}

export const ExternalEventList: React.FunctionComponent<ExternalEventListProps> = ({ events }) => {
  const sortedEvents = events.sort((a, b) => {
    return compareAsc(parseDateWithDefault(a.timestamp, new Date(0)), parseDateWithDefault(b.timestamp, new Date(0)));
  });

  return (
    <>
      <h3>External events</h3>
      <Table compact="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Qualifier</Table.HeaderCell>
            <Table.HeaderCell>Level</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>More</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {events.length === 0 ? (
            <Table.Row textAlign="center">
              <Table.Cell colSpan="7">No external events</Table.Cell>
            </Table.Row>
          ) : (
            sortedEvents.map((event, idx) => (
              <Table.Row key={idx} verticalAlign="top">
                <Table.Cell>{event.eventId}</Table.Cell>
                <Table.Cell>
                  {breakWordAtChar(event.eventSource.toLowerCase(), '_')}
                  <br />
                  <small>
                    {event.externalEventSource !== null && <span>{event.externalEventSource} </span>}
                    {event.sentByCompanyId !== null && (
                      <NavLink to={Routes.Company.generatePath({ company_id: event.sentByCompanyId })}>
                        #{event.sentByCompanyId}
                      </NavLink>
                    )}
                  </small>
                </Table.Cell>
                <Table.Cell>
                  {event.qualifier.toLowerCase()}
                  <br />
                  <small>{breakWordAtChar(event.externalEventQualifier, '.')}</small>
                </Table.Cell>
                <Table.Cell>
                  {isTourExternalStopEvent(event) ? (
                    <>
                      <i>#{event.stopId}</i>
                      <br />
                      <small>Stop</small>
                    </>
                  ) : isTourExternalDeliveryEvent(event) ? (
                    <>
                      <i>#{event.deliveryId}</i>
                      <br />
                      <small>Delivery</small>
                    </>
                  ) : isTourExternalEvent(event) ? (
                    'Tour'
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>
                  <TimestampLink timestamp={event.timestamp} />
                </Table.Cell>
                <Table.Cell>
                  <Popup wide hoverable trigger={<Icon name="code" />} on="hover">
                    {isTourExternalEvent(event) && (
                      <>
                        <div>Licence plate number: {event.licensePlateNumber ?? '-'}</div>
                        <div>Remote id: {event.remoteId ?? '-'}</div>
                        <div>Vehicle type: {notNil(event.vehicleType) ? event.vehicleType : '-'}</div>
                        <div>Location name: {event.locationName ?? '-'}</div>
                      </>
                    )}
                    <div>
                      Created at: <FormattedDate date={event.createdAt} />
                    </div>
                  </Popup>
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
};
